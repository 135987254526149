<template>
    <div id="utilisateurs">
        <ListActionsButtons
            :query="$refs?.filters?.query"
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />


        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :tabs-enabled="['basic']"
            :translation-name="translationName"
            :filters-overload="filtersOverload"
            @load-data="loadData()"
            @reset-pagination="$refs?.pagination?.resetPagination()"
        />
        <v-card class="pa-5">
            <ListUtilisateursPermissionsTable />

            <ListTable
                ref="table"
                :value-as-view-link="valueAsViewLink"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :init-sort="initSort"
                @reset-pagination="$refs?.pagination?.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
                @restore-data="restoreData($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name : "ListeUtilisateurs",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Utilisateurs",
            meta : [
                { name : 'description', content : "Gestion des utilisateurs" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            permissionName : 'user',
            requestName : 'users',
            defaultUrl : '/administration/utilisateurs',
            translationName : 'administration.utilisateurs',
            valueAsViewLink : ['username'],
            columns : [
                { dataKey : 'username', template : [this.showDisabledUser] },
                { dataKey : 'name' },
                { dataKey : 'email' },
                { dataKey : 'role', transform : [this.toTranslate], template : [this.isChips] },
                { dataKey : 'territory.t', template : [this.isChips] },
                { dataKey : 'last_connexion', template : [this.isDateTime] },
                { dataKey : 'credentials.created_at', template : [this.isDateTime] },
            ],
            initSort : {
                key : "name",
                order : "asc"
            },
            filtersOverload : ['username', 'name', 'email', 'role', 'territory_id', 'has_trashed', 'last_connexion'],
        }
    },
    methods : {
        showDisabledUser (key, value, template, item) {
            let html = value
            if (item.deleted) {
                html += ` <br/>
                        <v-chip color="error" size="small">
                            Désactivé
                        </v-chip>
                    `
            }
            return html
        },
    },
};
</script>
