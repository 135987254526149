<template>
    <ModalRight
        ref="modalParent"
        :custom-button-block="true"
        custom-button-color="dark"
        :saving="saving"
        :default-url="defaultUrl"
        :title-modal="titleModal"
        :is-form="isForm"
        :edit-disabled="!meta?.editable"
        :delete-disabled="!meta?.deletable"
        :permission-name="permissionName"
        :translation-name="$attrs['translation-name']"
        :data="fetchedData"
        @save="save"
        @close-modal="closeModal"
    >
        <template #content>
            <v-form
                ref="form"
                fast-fail
                @submit.prevent
            >
                <NuxtPage
                    ref="display"
                    :loading="loading"
                    :data="fetchedData"
                    :rules="rules"
                    :translation-name="translationName"
                />
            </v-form>
        </template>
    </ModalRight>
</template> 

<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'
export default {
    name : "Tournee",
    extends : idPageInit,
}
</script>
