<template>
    <ModalRight
        ref="modalParent"
        :custom-button-block="true"
        custom-button-color="dark"
        :saving="saving"
        :default-url="defaultUrl"
        :title-modal="titleModal"
        :is-form="isForm"
        :edit-disabled="true"
        :delete-disabled="true"
        :permission-name="permissionName"
        :translation-name="$attrs['translation-name']"
        :data="fetchedData"
        save-text="Confirmer le lancement de l'import"
        @save="save"
        @close-modal="closeModal"
    >
        <template #customButtons="{ item }">
            <v-tooltip
                v-if="item"
                text="Télécharger le fichier"
            >
                <template #activator="{ props }">
                    <DocumentsDownloadLink
                        :filename="item.file_name"
                        :url="item.download_url"
                    >
                        <v-btn
                            v-bind="props"
                            icon="mdi-download"
                            class="mr-2"
                            color="secondary"
                            variant="tonal"
                            size="x-small"
                        />
                    </DocumentsDownloadLink>
                </template>
            </v-tooltip>
        </template>
        <template #content>
            <v-form
                ref="form"
                fast-fail
                @submit.prevent
            >
                <NuxtPage
                    ref="display"
                    :loading="loading"
                    :data="fetchedData"
                    :rules="rules"
                    :translation-name="translationName"
                />
            </v-form>
        </template>
    </ModalRight>
</template>

<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'
export default {
    name : "IdImport",
    extends : idPageInit,
    data () {
        return {
        }
    }
}
</script>
