<template>
    <ModalRight
        ref="modalParent"
        :saving="saving"
        :default-url="$attrs['default-url']"
        :custom-button-block="true"
        custom-button-color="dark"
        :title-modal
        :is-form
        :edit-disabled="!meta?.editable"
        :delete-disabled="!meta?.deletable"
        :permission-name="permissionName"
        :translation-name="$attrs['translation-name']"
        :data="fetchedData"

        @save="save"
        @close-modal="closeModal"
    >
        <template #customButtons="{ item }">
            <v-tooltip
                v-if="item?.en_projet"
                text="Passer de en projet à actif"
            >
                <template #activator="{ props }">
                    <v-btn
                        v-if="$permissionCheck(`${permissionName}_project_edit`)"
                        v-bind="props"
                        icon="mdi-timer-sand"
                        class="mr-2"
                        color="warning"
                        variant="tonal"
                        size="x-small"
                        @click.prevent="$refs.modalParent?.redirectEdit(`${defaultUrl}/${item.k}/formulaire?en_projet=false`)"
                    />
                </template>
            </v-tooltip>
        </template>
        <template #content>
            <v-form
                ref="form"
                :fast-fail="true"
                @submit.prevent
            >
                <NuxtPage
                    ref="display"
                    :loading="loading"
                    :data="fetchedData"
                    :rules="rules"
                    :meta="meta"
                    :translation-name="$attrs['translation-name']"
                    :request-name="$attrs['request-name']"
                    @load-data="$emit('loadData')"
                    @close-modal="closeModal"
                />
            </v-form>
        </template>
    </ModalRight>
</template>

<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'

export default {
    name : "PeiPage",
    extends : idPageInit,
    emits: ['loadData'],
}
</script>
